import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './index.scss'

import ImgGem from '@/images/gem.png';

import { formatCharmValue } from '@/utils/format';
import { useTranslation } from 'react-i18next';

interface PropsType {
    selectedTab: number
    seconds?: number
    rankList: RoomSupport.RankItem[]
}

function index({ selectedTab, seconds: propsSeconds, rankList }: PropsType) {
    const { t } = useTranslation()
    const [seconds, setSeconds] = useState(propsSeconds || 0);
    const timer = useRef<NodeJS.Timeout>()

    const formatCountdown = useCallback((time: number) => {

        function _padZero(num: number): string {
            return num.toString().padStart(2, '0');
        }

        const days = Math.floor(time / (24 * 60 * 60));
        const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((time % (60 * 60)) / 60);
        const seconds = time % 60;

        return {
            d: _padZero(days),
            h: _padZero(hours),
            m: _padZero(minutes),
            s: _padZero(seconds)
        };
    }, [])

    const countdown = useMemo(() => {
        return formatCountdown(seconds)
    }, [seconds])

    const startCountdown = useCallback((curSeconds: number) => {
        if (curSeconds > 0) {
            timer.current = setInterval(() => {
                setSeconds(prevSeconds => {
                    if (prevSeconds - 1 <= 0) {
                        clearInterval(timer.current);
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }
    }, [])


    useEffect(() => {
        if (propsSeconds) {
            setSeconds(propsSeconds)
            startCountdown(propsSeconds)
        }
    }, [propsSeconds])

    useEffect(() => {
        return () => {
            clearInterval(timer.current);
        }
    }, [])
    return (
        <div className='cmp-rank'>
            {selectedTab === 0 && <div className='cmp-rank__countdown'>
                <span>{t('Common.Countdown')}</span>
                <div className='cmp-rank__countdown-num'>
                    {countdown.d}
                </div>
                <span>{t('Common.Days')}</span>
                <div className='cmp-rank__countdown-num'>
                    {countdown.h}
                </div>
                <span>:</span>
                <div className='cmp-rank__countdown-num'>
                    {countdown.m}
                </div>
                <span>:</span>
                <div className='cmp-rank__countdown-num'>
                    {countdown.s}
                </div>
            </div>}
            {rankList?.length > 0 &&
                <div className='cmp-rank__list'>
                    {rankList.map(item =>
                        <div key={item.roomName} className='cmp-rank__list-item'>
                            <div className='cmp-rank__list-item-index'>
                                <div className={`rank${item.index}`}>{item.index && Number(item.index) <= 3 ? '' : item.index}</div>
                            </div>
                            <div className='cmp-rank__list-item-info'>
                                <img className='cmp-rank__list-item-avatar' src={item.avatar} alt=''></img>
                                <div className='cmp-rank__list-item-nick'>{item.roomName}</div>
                                <div className='cmp-rank__list-item-left'>
                                    <img className='cmp-rank__list-item-icon' src={ImgGem} alt=''></img>
                                    <span className='cmp-rank__list-item-num'>{item.totalNumber && formatCharmValue(item.totalNumber)}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default memo(index)